import React from "react"
import Text from "@ui/text"
import Anchor from "@ui/anchor"
import { BlogMetaWrap } from "./news-count.style"
import { CommentCount } from "disqus-react"
import { useStaticQuery, graphql } from "gatsby";

const NewsCount = ({ icon, url, id, title, ...restProps }) => {
  const readUrl = useStaticQuery(graphql`
    query newsSiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const basUrl = readUrl.site.siteMetadata.siteUrl
  const disqusShortname = "tulipacademy"
  return (
    <BlogMetaWrap {...restProps}>
      <Anchor>
        <i className={icon}></i>
        <Text as="span">
          <CommentCount
            shortname={disqusShortname}
            config={{
              url: basUrl + url,
              identifier: id,
              title: title,
            }}
          >
            {/* Placeholder Text */}
            Comments
          </CommentCount>
        </Text>
      </Anchor>
    </BlogMetaWrap>
  )
}

export default NewsCount
